<template>
  <dl class="terms">
    <dt>{{$t('content.signup.popup.Terms.txt02')}}</dt>
    <dd>{{$t('content.signup.popup.Terms.txt03')}}</dd>

    <dt>{{$t('content.signup.popup.Terms.txt04')}}</dt>
    <dd>{{$t('content.signup.popup.Terms.txt05')}}<br>
    {{$t('content.signup.popup.Terms.txt06')}}<br>
    {{$t('content.signup.popup.Terms.txt07')}}<br>
    {{$t('content.signup.popup.Terms.txt08')}}<br>
    {{$t('content.signup.popup.Terms.txt09')}}<br>
    {{$t('content.signup.popup.Terms.txt10')}}<br>
    {{$t('content.signup.popup.Terms.txt11')}}</dd>

    <dt>{{$t('content.signup.popup.Terms.txt12')}}</dt>
    <dd>{{$t('content.signup.popup.Terms.txt13')}}<br>
    {{$t('content.signup.popup.Terms.txt14')}}<br>
    {{$t('content.signup.popup.Terms.txt15')}}<br>
    {{$t('content.signup.popup.Terms.txt16')}}</dd>

    <dt>{{$t('content.signup.popup.Terms.txt17')}}</dt>
    <dd>{{$t('content.signup.popup.Terms.txt18')}}<br>
    {{$t('content.signup.popup.Terms.txt19')}}<br>
    {{$t('content.signup.popup.Terms.txt20')}}<br>
    {{$t('content.signup.popup.Terms.txt21')}}<br>
    {{$t('content.signup.popup.Terms.txt22')}}<br>
    {{$t('content.signup.popup.Terms.txt23')}}<br>
    {{$t('content.signup.popup.Terms.txt24')}}<br>
    {{$t('content.signup.popup.Terms.txt25')}}<br>
    {{$t('content.signup.popup.Terms.txt26')}}<br>
    {{$t('content.signup.popup.Terms.txt27')}}<br>
    {{$t('content.signup.popup.Terms.txt28')}}<br>
    {{$t('content.signup.popup.Terms.txt29')}}<br>
    {{$t('content.signup.popup.Terms.txt30')}}<br>
    {{$t('content.signup.popup.Terms.txt31')}}<br>
    {{$t('content.signup.popup.Terms.txt32')}}<br>
    {{$t('content.signup.popup.Terms.txt33')}}</dd>

    <dt>{{$t('content.signup.popup.Terms.txt34')}}</dt>
    <dd>{{$t('content.signup.popup.Terms.txt35')}}</dd>

    <dt>{{$t('content.signup.popup.Terms.txt36')}}</dt>
    <dd>{{$t('content.signup.popup.Terms.txt37')}}</dd>

    <dt>{{$t('content.signup.popup.Terms.txt38')}}</dt>
    <dd>{{$t('content.signup.popup.Terms.txt39')}}<br>
    {{$t('content.signup.popup.Terms.txt40')}}<br>
    {{$t('content.signup.popup.Terms.txt41')}}</dd>

    <dt>{{$t('content.signup.popup.Terms.txt42')}}</dt>
    <dd>{{$t('content.signup.popup.Terms.txt43')}}<br>
    {{$t('content.signup.popup.Terms.txt44')}}</dd>

    <dt>{{$t('content.signup.popup.Terms.txt45')}}</dt>
    <dd>{{$t('content.signup.popup.Terms.txt46')}}<br>
    {{$t('content.signup.popup.Terms.txt47')}}<br>
    {{$t('content.signup.popup.Terms.txt48')}}<br>
    {{$t('content.signup.popup.Terms.txt49')}}<br>
    {{$t('content.signup.popup.Terms.txt50')}}<br>
    {{$t('content.signup.popup.Terms.txt51')}}<br>
    {{$t('content.signup.popup.Terms.txt52')}}<br>
    {{$t('content.signup.popup.Terms.txt53')}}<br>
    {{$t('content.signup.popup.Terms.txt54')}}</dd>

    <dt>{{$t('content.signup.popup.Terms.txt55')}}</dt>
    <dd>{{$t('content.signup.popup.Terms.txt56')}}<br>
    {{$t('content.signup.popup.Terms.txt57')}}<br>
    {{$t('content.signup.popup.Terms.txt58')}}</dd>

    <dt>{{$t('content.signup.popup.Terms.txt59')}}</dt>
    <dd>{{$t('content.signup.popup.Terms.txt60')}}<br>
    {{$t('content.signup.popup.Terms.txt61')}}</dd>

    <dt>{{$t('content.signup.popup.Terms.txt62')}}</dt>
    <dd>{{$t('content.signup.popup.Terms.txt63')}}<br>
    {{$t('content.signup.popup.Terms.txt64')}}<br>
    {{$t('content.signup.popup.Terms.txt65')}}<br>
    {{$t('content.signup.popup.Terms.txt66')}}</dd>

    <dt>{{$t('content.signup.popup.Terms.txt67')}}</dt>
    <dd>{{$t('content.signup.popup.Terms.txt68')}}<br>
    {{$t('content.signup.popup.Terms.txt69')}}<br>
    {{$t('content.signup.popup.Terms.txt70')}}<br>
    {{$t('content.signup.popup.Terms.txt71')}}<br>
    {{$t('content.signup.popup.Terms.txt72')}}<br>
    {{$t('content.signup.popup.Terms.txt73')}}<br>
    {{$t('content.signup.popup.Terms.txt74')}}<br>
    {{$t('content.signup.popup.Terms.txt75')}}<br>
    {{$t('content.signup.popup.Terms.txt76')}}<br>
    {{$t('content.signup.popup.Terms.txt77')}}<br>
    {{$t('content.signup.popup.Terms.txt78')}}</dd>

    <dt>{{$t('content.signup.popup.Terms.txt79')}}</dt>
    <dd>{{$t('content.signup.popup.Terms.txt80')}}<br>
    {{$t('content.signup.popup.Terms.txt81')}}<br>
    {{$t('content.signup.popup.Terms.txt82')}}<br>
    {{$t('content.signup.popup.Terms.txt83')}}<br>
    {{$t('content.signup.popup.Terms.txt84')}}<br>
    {{$t('content.signup.popup.Terms.txt85')}}<br>
    {{$t('content.signup.popup.Terms.txt86')}}</dd>

    <dt>{{$t('content.signup.popup.Terms.txt87')}}</dt>
    <dd>{{$t('content.signup.popup.Terms.txt88')}}<br>
    {{$t('content.signup.popup.Terms.txt89')}}<br>
    {{$t('content.signup.popup.Terms.txt90')}}<br>
    {{$t('content.signup.popup.Terms.txt91')}}<br>
    {{$t('content.signup.popup.Terms.txt92')}}</dd>

    <dt>{{$t('content.signup.popup.Terms.txt93')}}</dt>
    <dd>{{$t('content.signup.popup.Terms.txt94')}}<br>
    {{$t('content.signup.popup.Terms.txt95')}}</dd>

    <dt>{{$t('content.signup.popup.Terms.txt96')}}</dt>
    <dd>{{$t('content.signup.popup.Terms.txt97')}}</dd>
  </dl>
</template>

<script>
/**
 * 이용약관 (내용) Component
 * @see Terms.vue
 * @see SignUpStep3.vue
 */
export default {
  name: 'TermsText',
};
</script>
