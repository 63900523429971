<template>
  <div class="form-container signup_step3">
    <div class="align-right">
      <!-- *는 필수입력 항목입니다. -->
      <span class="required-mark">*</span>{{ $t('content.networking.CompanyInfo.txt53') }}
    </div>

    <ValidationObserver v-slot="{ handleSubmit, invalid }">
      <form @submit.prevent="handleSubmit(doNext)">
        <fieldset>
          <legend>{{ $t("signUp.title.agreement") }}</legend>

          <!-- 전체 동의 -->
          <div class="check-all">
            <div>
              <BaseCheckbox
                id="allAgree"
                v-model="allAgreed"
                @change="onAllAgreedChange"
              >{{ $t('content.signup.Agreement.txt01') }}</BaseCheckbox>
            </div>
          </div>
          <!-- // 전체 동의 -->

          <!-- 회원 가입 약관 (필수) -->
          <div class="terms-area">
            <!-- required : https://logaretm.github.io/vee-validate/guide/rules.html#required -->
            <ValidationProvider
              :name="$t('content.signup.Agreement.txt02')"
              :rules="{ required: { allowFalse: false } }"
            >
              <BaseCheckbox
                id="agree1"
                v-model="joinAgreed"
                required
              >{{ $t('content.signup.Agreement.txt02') }}</BaseCheckbox>
            </ValidationProvider>

            <div class="terms-container">
              <TermsText />
            </div>
          </div>
          <!-- // 회원 가입 약관 (필수) -->

          <!-- 개인정보 수집 및 이용 (필수) -->
          <div class="terms-area">
            <!-- required : https://logaretm.github.io/vee-validate/guide/rules.html#required -->
            <ValidationProvider
              :name="$t('content.signup.Agreement.txt06')"
              :rules="{ required: { allowFalse: false } }"
            >
              <BaseCheckbox
                id="agree2"
                v-model="privateMandatoryAgreed"
                required
              >{{ $t('content.signup.Agreement.txt06') }}</BaseCheckbox>
            </ValidationProvider>

            <div class="terms-container">
              <div class="view-box">
                <dl class="terms">
                  <dt>{{$t('content.signup.popup.PersonalInfo.txt03')}}</dt>
                  <dd>{{$t('content.signup.popup.PersonalInfo.txt04')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt05')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt06')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt07')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt08')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt09')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt12')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt13')}}<br />
                  <br />
                  </dd>

                  <dt>{{$t('content.signup.popup.PersonalInfo.txt23')}}</dt>
                  <dd>{{$t('content.signup.popup.PersonalInfo.txt24')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt25')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt26')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt27')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt28')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt29')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt30')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt31')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt32')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt33')}}<br />
                  <br />
                  </dd>
                  <dt>{{$t('content.signup.popup.PersonalInfo.txt78')}}</dt>
                </dl>
              </div>
            </div>
          </div>
          <!-- // 개인정보 수집 및 이용 (필수) -->
          <div class="terms-area">
            <ValidationProvider
              :name="$t('content.signup.Agreement.txt07')"
              :rules="{ required: { allowFalse: true } }"
            >
              <BaseCheckbox
                id="agree3"
                v-model="privateOptionalAgreed"
              >{{ $t('content.signup.Agreement.txt07') }}</BaseCheckbox>
            </ValidationProvider>

            <div class="terms-container">
              <div class="view-box">
                <dl class="terms">
                  <dt>{{$t('content.signup.popup.PersonalInfo.txt03')}}</dt>
                  <dd>{{$t('content.signup.popup.PersonalInfo.txt04')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt05')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt06')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt07')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt08')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt09')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt15')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt16')}}<br />
                  <br />
                  </dd>

                  <dt>{{$t('content.signup.popup.PersonalInfo.txt23')}}</dt>
                  <dd>{{$t('content.signup.popup.PersonalInfo.txt24')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt25')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt26')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt27')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt28')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt29')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt30')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt31')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt32')}}<br />
                  {{$t('content.signup.popup.PersonalInfo.txt33')}}<br /><br />
                  </dd>

                  <dt>{{$t('content.signup.popup.PersonalInfo.txt79')}}</dt>
                </dl>
              </div>
            </div>
          </div>
          <!-- // 개인정보 수집 및 이용 (선택) -->

          <!-- 14세 이상 (필수) -->
          <div class="terms-area">
            <!-- required : https://logaretm.github.io/vee-validate/guide/rules.html#required -->
            <ValidationProvider
              :name="$t('content.signup.Agreement.txt08')"
              :rules="{ required: { allowFalse: false } }"
            >
              <BaseCheckbox
                id="agree4"
                v-model="ageAgreed"
                required
              >{{ $t('content.signup.Agreement.txt08') }}</BaseCheckbox>
            </ValidationProvider>

            <div class="terms-container">
              <dt>{{$t('content.signup.popup.AgeInfo.txt01')}}</dt>
            </div>
          </div>
          <!-- // 14세 이상 (필수) -->
        </fieldset>

        <div class="btn_bottom_wrap">
          <BaseButton
            type="line"
            @click="cancel"
          >{{$t('content.common.button.btn05')}}</BaseButton>
          <BaseButton
            nativeType="submit"
            :disabled="invalid"
          >{{$t('content.common.button.btn02')}}</BaseButton>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import sha512 from 'sha512'; // http://cryptocoinjs.com/modules/crypto/sha512/
import { APIs } from '@/common/portalApi';
import routeTypes from '@/router/routeTypes';
import TermsText from '@/views/legal/component/TermsText.vue';

export default {
  name: 'SignUpStep3',
  mixins: [mixinHelperUtils],
  components: {
    ValidationObserver, // https://logaretm.github.io/vee-validate/api/validation-observer.html
    ValidationProvider, // https://logaretm.github.io/vee-validate/api/validation-provider.html
    BaseButton,
    BaseCheckbox,
    TermsText,
  },

  props: {
    userInfo: {
      type: Object,
      required: true,
    },
  },
  metaInfo() {
    return {
      meta: this.$route.meta.metaInfo
    };
  },
  data() {
    return {
      allAgreed: false,
      joinAgreed: false,
      privateMandatoryAgreed: false,
      dreamAgreed: false,
      ageAgreed: false,
    };
  },

  mounted() {
    if (!this.userInfo) {
      this.cancel();
    }
  },

  watch: {
    joinAgreed(newVal) {
      this.allAgreed = newVal && this.privateMandatoryAgreed && this.ageAgreed;
    },
    privateMandatoryAgreed(newVal) {
      this.allAgreed = this.joinAgreed && newVal && this.ageAgreed;
    },
    ageAgreed(newVal) {
      this.allAgreed = this.joinAgreed && this.privateMandatoryAgreed && newVal;
    },
  },

  methods: {
    cancel() {
      this.$router.replace({
        name: routeTypes.ROUTE_NAME_SIGNUP,
      });
    },
    doNext() {
      const user = {
        ...this.userInfo,
        join_terms: 'Y',
        private_collect_terms: 'Y',
        combine_join_terms: this.dreamAgreed ? 'Y' : 'N',
        email: sha512(this.userInfo.email),
      };

      this.portalApiClient({
        url: APIs.MEMBER_SNS_JOIN,
        data: user,
      }).then(({ status }) => {
        if (status === 200) {
          this.$router.replace({
            name: 'SignUpStep4',
            params: {
              email: this.userInfo.email,
              isSns: true,
            },
          });
        }
      });
    },

    onAllAgreedChange(value) {
      this.joinAgreed = value;
      this.privateMandatoryAgreed = value;
      this.privateOptionalAgreed = value;
      this.ageAgreed = value;
    },
  },
};
</script>
<style>
.form-container{ margin-top: 4rem;}
</style>
